import React from 'react';

import { ModuleWrapper } from '../moduleWrapper';
import { MinorCardCarousel as Carousel } from '@components/carousels/minorCardCarousel';

const MinorCardCarousel = ({ config, title, items }) => {
  return (
    <ModuleWrapper {...config}>
      <Carousel items={items} title={title} />
    </ModuleWrapper>
  );
};

export default MinorCardCarousel;
