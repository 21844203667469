import React from 'react';
import { Link } from 'gatsby';

import { useSanityPath } from '@hooks/useSanityPath';
import { SanityImage } from '@components/sanity/sanityImage';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

export const MinorCard = ({ title, subtitle, page, image }) => {
  const path = useSanityPath(page);
  const breakpoints = useBreakpoint();
  return (
    <Link
      to={path}
      className="relative flex group scale-95 transform hover:scale-100 transition-all duration-500"
    >
      {breakpoints.md ? (
        <>
          <div className="absolute bottom-4 px-4 z-10 text-white lg:p-6">
            <h4 className="blockH4 transform translate-y-full  group-hover:translate-y-0 transition-all group-hover:font-bold duration-500">
              {title}
            </h4>
            <h5 className="blockH6 transform translate-y-full opacity-0 group-hover:opacity-100 group-hover:translate-y-0  transition-all underline duration-500">
              {subtitle || 'Shop Now'}
            </h5>
          </div>
          <div className="aspect-h-1 aspect-w-1 w-full rounded-lg overflow-hidden z-0 relative bg-black transform">
            <SanityImage
              image={image}
              className="absolute inset-0 object-cover opacity-60"
              width={600}
            />
          </div>
        </>
      ) : (
        <span className="btn large">{title}</span>
      )}
    </Link>
  );
};
